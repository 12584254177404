import { recentConstants } from "../_constants/Redux/footerActive";
const INITIAL_STATE = {
  activeFooter: 0,
  lastActive: 0,
};

export default function active(state = INITIAL_STATE, action) {
  switch (action.type) {
    case recentConstants.ACTIVE_FOOTER:
      return {
        ...state,
        lastActive: state.activeFooter,
        activeFooter: action.data,
      };
    default:
      return state;
  }
}
