import {
  SET_OTP_UID,
  SET_USER_INFO,
  UPDATE_USER_INFO,
  UPDATE_USER_TU_INFO,
} from "./types";

const initialState = {};

export const userInfoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_INFO:
      return { ...payload };
    case UPDATE_USER_INFO:
      return { ...state, ...payload };
    case UPDATE_USER_TU_INFO: {
      return { ...state, tu_blocked: payload };
    }
    case SET_OTP_UID: {
      return { ...state, otpUid: payload };
    }
    default:
      return state;
  }
};
